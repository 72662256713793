/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 21:38:04
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 15:51:16
 */
import { wGetImgList } from '@/plugins/utils'
import EnumMap from '@/plugins/enumMap'
export default {
  name: 'CustomDetail',
  data () {
    return {
      form: {
        businessLicense: [],
        user_list: []
      }
    }
  },
  methods: {
    async init () {
      const id = this.$route.params.id
      const data = await this.$wPost('/operate/custom/get.do', { id })
      console.log(data)
      for (const i in data) {
        this.$set(this.form, i, data[i])
      }
      if (this.form.custom_operate_license_vo) {
        const fileList = wGetImgList(this.form.custom_operate_license_vo.license_path, this.form.custom_operate_license_vo.file_name)
        this.$set(this.form, 'businessLicense', fileList)
      }
    },
    detail () {
      this.$router.push({ path: `/operate/custom/edit/${this.form.custom_id}` })
    },
    cancel () {
      history.go(-1)
    },
    customStatus (val) {
      const name = new EnumMap('custom_status').mapping(val)
      return name
    },
    previewImg (file, index) {
      let url = ''
      if (file.response) {
        url = file.response.data
      } else {
        url = file.url
      }
      window.open(url)
    },
    editCustomBind () {
      this.$refs.customBind.show()
    },
    reject () {
      this.$refs.customReject.show()
    }
  },
  created () {
    this.init()
  }
}
