/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 21:38:11
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 21:48:34
 */
import CustomDetail from './CustomDetail.vue'
export default CustomDetail
